import { merge } from 'lodash';
import ongoingClient from '@/services/ongoingClient';
import crudHelper from '../helpers/crud';

const customersActions = {
  READ: ongoingClient.getCustomer.bind(ongoingClient),
  READ_LIST: ongoingClient.getCustomers.bind(ongoingClient),
  CREATE: ongoingClient.newCustomer.bind(ongoingClient),
  UPDATE: ongoingClient.updateCustomer.bind(ongoingClient),
  DELETE: ongoingClient.deleteCustomer.bind(ongoingClient),
};

const customersStore = crudHelper('customer',
  'externalId',
  customersActions);

export default merge({ namespaced: true }, customersStore);
