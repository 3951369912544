import { merge } from "lodash";
import apiClient from "@/services/apiClient";
import crudHelper from "../helpers/crud";

const tenantActions = {
  READ: apiClient.getTenant.bind(apiClient),
  READ_LIST: apiClient.getTenants.bind(apiClient),
  CREATE: apiClient.addTenant.bind(apiClient),
  UPDATE: apiClient.updateTenant.bind(apiClient),
  DELETE: apiClient.deleteTenant.bind(apiClient),
};

const tenantStore = crudHelper("tenant", "tenantname", tenantActions);

const module = merge({ namespaced: true }, tenantStore);
export default module;
