<template>
  <v-list-group v-if="this.features.useBatch">
    <template v-slot:activator>
      <v-list-item-icon>
        <v-icon>playlist_play</v-icon>
      </v-list-item-icon>
      <v-list-item-title>Batches</v-list-item-title>
    </template>

    <v-divider />
    <DrawerItem
      title="Batches list"
      route="/batches"
      icon="keyboard_arrow_right"
    />
    <DrawerItem
      title="Create batch"
      route="/batches/new"
      icon="keyboard_arrow_right"
    />
    <DrawerItem
      title="Input files"
      route="/batches/input-files"
      icon="keyboard_arrow_right"
    />
    <v-divider />
  </v-list-group>
</template>

<script>
import DrawerItem from '../DrawerItem';

export default {
  components: { DrawerItem },
  props: {
    features: Object,
  },
};
</script>
