import { merge } from "lodash";
import apiClient from "@/services/apiClient";
import crudHelper from "@/store/helpers/crud";

const dataSourceActionMapping = {
  READ_LIST: apiClient.getDataSources.bind(apiClient),
};

const countriesActionMapping = {
  READ_LIST: apiClient.getCountries.bind(apiClient),
};

const summaryReportActionMapping = {
  READ: apiClient.getSummaryReport.bind(apiClient),
  READ_LIST: apiClient.getSummaryReports.bind(apiClient),
};

const newsummaryReportActionMapping = {
  READ: apiClient.getNewSummaryReports.bind(apiClient),
  READ_LIST: apiClient.getNewSummaryReports.bind(apiClient),
};

const format = (data) =>
  data?.dataSources?.map((item) => ({
    value: item.sourceId,
    text: `${item.displayName} (${item.category}) `,
    category: item.category,
    parserName: item.parserName,
  }));

// eslint-disable-next-line no-unused-vars
function formatReport(data) {
  // console.log(data);
  return data;
}

const dataSourceStore = crudHelper("dataSource", "sourceId", dataSourceActionMapping, format);
const countriesStore = crudHelper("country", null, countriesActionMapping);
const summaryReportStore = crudHelper("summaryReport", "versionId", summaryReportActionMapping);
const newsummaryReportStore = crudHelper("newsummaryReport", null, newsummaryReportActionMapping);

const module = merge(
  {},
  dataSourceStore,
  countriesStore,
  summaryReportStore,
  newsummaryReportStore
);
export default module;
