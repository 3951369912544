import BaseAPIClient from '@/services/baseClient';

export class GeoAPIClient extends BaseAPIClient {
  createInstance() {
    super.createInstance();
    this.instance.defaults.baseURL = process.env.NODE_ENV === 'production'
      ? '/api/v1/geo/'
      : process.env.VUE_APP_GEO_API_URI;
  }

  async search(address) {
    return this.instance.post('search', { address });
  }
}

export default new GeoAPIClient();
