import {
  isString, isArray, merge, find,
} from 'lodash';
import {
  CREATE, DELETE, ERROR, READ, READ_LIST, SUCCESS, UPDATE,
} from '@/store/actions/crud';
import { authorize } from '@/store/helpers/auth';

export const stateFactory = (resource) => {
  // single resource
  if (isString(resource)) {
    return {
      [resource]: {
        list: {
          value: [],
          status: '',
          loadedOnce: false,
        },
        single: {
          value: {},
          status: '',
          loadedOnce: false,
          createdOnce: false,
          updatedOnce: false,
          deletedOnce: false,
        },
      },
    };
    // multiple resources
  }
  if (isArray(resource)) {
    return resource.reduce((result, current) => ({ ...result, ...stateFactory(current) }), {});
  }
  throw new Error('Incorrect resource type');
};

export const getterFactory = (resource, transform = (t) => t) => {
  // single resource
  if (isString(resource)) {
    return {
      [`${resource}`]: (state) => {
        const { value } = state[resource].single;
        return value ? transform(value) : value;
      },
      [`${resource}List`]: (state) => {
        const { value } = state[resource].list;
        return value ? transform(value) : value;
      },
    };
  }
  // multiple resources
  if (isArray(resource)) {
    return resource.reduce((result, current) => ({ ...result, ...getterFactory(current) }), {});
  }
  throw new Error('Incorrect resource type');
};

export const readMutationFactory = (resource) => ({
  [`${READ}_${resource}`]: (state) => {
    state[resource].single.status = 'loading';
  },
  [`${READ}_${resource}_${SUCCESS}`]: (state, { result }) => {
    state[resource].single.status = 'success';
    state[resource].single.value = result;
    state[resource].single.hasLoadedOnce = true;
  },
  [`${READ}_${resource}_${ERROR}`]: (state) => {
    state[resource].single.status = 'error';
    state[resource].single.hasLoadedOnce = true;
  },
});

export const readListMutationFactory = (resource) => ({
  [`${READ_LIST}_${resource}`]: (state) => {
    state[resource].list.status = 'loading';
  },
  [`${READ_LIST}_${resource}_${SUCCESS}`]: (state, { result }) => {
    state[resource].list.status = 'success';
    state[resource].list.value = result;
    state[resource].list.hasLoadedOnce = true;
  },
  [`${READ_LIST}_${resource}_${ERROR}`]: (state) => {
    state[resource].list.status = 'error';
    state[resource].list.hasLoadedOnce = true;
  },
});

export const createMutationFactory = (resource) => ({
  [`${CREATE}_${resource}`]: (state) => {
    state[resource].single.status = 'creating';
  },
  [`${CREATE}_${resource}_${SUCCESS}`]: (state, { result }) => {
    state[resource].single.status = 'success';
    state[resource].single.value = result;

    state[resource].list.value.push(result);
    state[resource].single.createdOnce = true;
  },
  [`${CREATE}_${resource}_${ERROR}`]: (state) => {
    state[resource].single.status = 'error';
    state[resource].single.createdOnce = true;
  },
});

export const updateMutationFactory = (resource, key) => ({
  [`${UPDATE}_${resource}`]: (state) => {
    state[resource].single.status = 'updating';
  },
  [`${UPDATE}_${resource}_${SUCCESS}`]: (state, { result }) => {
    state[resource].single.status = 'success';
    state[resource].single.value = result;

    const { list } = state[resource];
    if (list && list.value && list.value.length > 0) {
      const item = find(list.value, (v) => v[key] === result[key]);
      merge(item, result);
    }
    state[resource].single.updatedOnce = true;
  },
  [`${UPDATE}_${resource}_${ERROR}`]: (state) => {
    state[resource].single.status = 'error';
    state[resource].single.updatedOnce = true;
  },
});

export const deleteMutationFactory = (resource, key) => ({
  [`${DELETE}_${resource}`]: (state) => {
    state[resource].single.status = 'deleting';
  },
  [`${DELETE}_${resource}_${SUCCESS}`]: (state, { payload }) => {
    state[resource].single.status = 'success';
    state[resource].single.value = {};

    state[resource].list.value = state[resource].list.value.filter((v) => v[key] !== payload[key]);

    state[resource].single.deletedOnce = true;
  },
  [`${DELETE}_${resource}_${ERROR}`]: (state) => {
    state[resource].single.status = 'error';
    state[resource].single.deletedOnce = true;
  },
});

export const actionFactory = (resource, action, actionHandler) => {
  const resourceAction = `${action}_${resource}`;

  return ({
    [resourceAction]: async ({ commit, dispatch }, payload) => {
      commit(resourceAction);
      try {
        const resp = await actionHandler(payload);
        commit(`${resourceAction}_${SUCCESS}`, { payload, result: resp.data });
        return resp.data;
      } catch (err) {
        authorize(err, dispatch);
        commit(`${resourceAction}_${ERROR}`, err);
        return err;
      }
    },
  });
};

export default function createCrudStore(resource, uniqueKey, actionMapping, getterTransform) {
  const state = stateFactory(resource);
  const getters = getterFactory(resource, getterTransform);
  const actions = {
    ...Object.entries(actionMapping)
      .reduce((acc, cur) => ({ ...acc, ...actionFactory(resource, cur[0], cur[1]) }), {}),
  };
  const mutations = {
    ...readMutationFactory(resource),
    ...readListMutationFactory(resource),
    ...createMutationFactory(resource),
    ...updateMutationFactory(resource, uniqueKey),
    ...deleteMutationFactory(resource, uniqueKey),
  };

  return {
    state,
    getters,
    actions,
    mutations,
  };
}
