<template>
  <v-container class="justify-center">
    <LoginForm />
  </v-container>
</template>

<script>
import LoginForm from "../components/LoginForm";

export default {
  components: {
    LoginForm,
  },
  destroyed() {
    this.$router.go();
  },
  name: "Login",
};
</script>

<style scoped></style>
