import axios from "axios";
import BaseAPIClient from "@/services/baseClient";

export class ApiClient extends BaseAPIClient {
  createInstance() {
    super.createInstance();

    this.baseURL = process.env.NODE_ENV === "production" ? "/api/v1" : process.env.VUE_APP_API_URI;
    this.instance.defaults.baseURL = this.baseURL;

    this.instance.defaults.transformResponse = [].concat(
      // work-around to get parsed string to JSON
      axios.defaults.transformResponse,
      // pulling data to default path
      (data) => data.resultData
    );
  }

  async authenticate(user) {
    return this.instance.post("authentications/authenticate", user);
  }

  async search(query) {
    // return this.instance.post('/queries/aml', query);
    // console.log("query search :>> ", query);
    return this.instance.post("/queries/aml-status", query);
  }

  async updateStatus(ids, status) {
    return this.instance.put(`/query-log`, { ids, status });
  }

  async geoSearch(query) {
    return this.instance.post("/queries/aml", query);
  }

  async getDataSources() {
    return this.instance.get("/summary-reports/current");
  }

  async getCountries() {
    return this.instance.get("/countries");
  }

  async getEntity(id) {
    return this.instance.get(`/entities/${id}`);
  }

  async recognizeImage(file) {
    const formData = new FormData();
    formData.append("image", file);

    return this.instance.post("/vision/text", formData);
  }

  async getQueryLog(params) {
    return this.instance.get("/queries", { params });
  }

  async getQueryLogDetails(id) {
    return this.instance.get(`/queries/${id}`);
  }

  async getUsageReport(params) {
    return this.instance.get("/reports/daily", {
      params,
      transformResponse: axios.defaults.transformResponse,
    });
  }

  async getUsageByUserReport(params) {
    return this.instance.get("/reports/activity", {
      params,
      transformResponse: axios.defaults.transformResponse,
    });
  }

  async getSummaryReport(id) {
    if (id) {
      return this.instance.get(`/summary-reports/${id}`);
    }
    return this.instance.get("/summary-reports/current");
  }

  async getSummaryReports() {
    return this.instance.get("/summary-reports");
  }

  async getNewSummaryReports() {
    return this.instance.get("/summary-reports-new");
  }
  // ### USERS SECTION
  async getUsers() {
    return this.instance.get("/user-management/users");
  }

  async getUser(username) {
    return this.instance.get(`/user-management/users/${username}`);
  }

  async addUser(user) {
    return this.instance.post("/user-management/users", user);
  }

  async updateUser(update) {
    const { username } = update;
    return this.instance.put(`/user-management/users/${username}`, update);
  }

  async deleteUser(user) {
    const { username } = user;
    return this.instance.delete(`/user-management/users/${username}`);
  }

  async getDeletedUsers() {
    return this.instance.get("/user-management/deleted-users");
  }

  async changePassword(username, password, newPassword) {
    return this.instance.patch(`/user-management/users/${username}/password`, {
      password,
      newPassword,
    });
  }

  async changeUserSettings(username, settings) {
    return this.instance.patch(`/user-management/users/${username}/settings`, {
      settings,
    });
  }

  async restoreDeletedUser(username) {
    return this.instance.put(`/user-management/users/${username}`);
  }
  // ### TENANTS SECTION
  async getTenants() {
    return this.instance.get("/tenant-management/tenants");
  }

  async getTenant(tenantid) {
    return this.instance.get(`/tenant-management/tenants/${tenantid}`);
  }

  async addTenant(tenant) {
    return this.instance.post("/tenant-management/tenants", tenant);
  }

  async updateTenant(update) {
    const { id } = update;
    return this.instance.put(`/tenant-management/tenants/${id}`, update);
  }

  async deleteTenant(tenantid) {
    return this.instance.delete(`/tenant-management/tenants/${tenantid}`);
  }

  async getDeletedTenant() {
    return this.instance.get("/tenant-management/deleted-tenants");
  }

  async getDailyReport(date) {
    return this.instance.get(`/report/${date}`);
  }

  async compareNames(source, target, isIndividual) {
    return this.instance.post("/names/compare", {
      nameA: source,
      nameB: target,
      isIndividual,
      showFullResults: true,
    });
  }

  logout = async () => await this.instance.post('authentications/logout', {})

  async adverseSearch(searchFor) {
    return this.instance.post("/adverse/search/", { searchFor });
  }

  async openDocs() {
    const url = `${this.baseURL}/docs`;
    window.open(url, "blank");
  }
}

export default new ApiClient();
