import { range } from 'lodash';
import moment from 'moment';
import * as momentTz from 'moment-timezone';

const dateFormat = localStorage.getItem('dateFormat') || 'YYYY-MM-DD HH:mm:ss'
const currentTimezone =  localStorage.getItem('timezone') || 'America/Chicago'

export const lifeYearRange = (life = 120) => {
  const currentYear = new Date().getFullYear();
  return range(currentYear, currentYear - life);
};

export const months = () => moment.months();

export const days = (year, month) => range(1, moment(`${year}-${month}`).daysInMonth());

// export const format = (time) => moment(time).utc(true).format(dateFormat);
export const format = (time) =>  momentTz.tz(time, currentTimezone).format(dateFormat);

export const isValid = (time, timeFormat = 'YYYY-MM-DDTHH:mm:ssZ') => moment(time, timeFormat, true).isValid();

export const removeLeadingZeros = (date) => {
  if (!date) {
    return date;
  }
  return date.split('/').map((value) => `${+value}`).join('/');
};

export const padLeadingZeros = (date) => {
  if (!date || date.length === 4) {
    return date;
  }

  const [day, month, year] = date.split('/');
  const paddedDay = day.length === 2 ? day : `0${day}`;
  const paddedMonth = month.length === 2 ? month : `0${month}`;

  return `${paddedDay}/${paddedMonth}/${year}`;
};

export const calcDiffBetweenDates = (current, past) => moment.duration(current.diff(past)).asHours().toFixed(2);

export default {
  lifeYearRange, months, days, format, isValid, removeLeadingZeros, calcDiffBetweenDates,
};
