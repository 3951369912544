import axios from 'axios';

export default class BaseAPIClient {
  instance = null;

  constructor(unauthorizedHandler) {
    this.createInstance();
    this.setInterceptors(unauthorizedHandler);
    this.initToken();
  }

  createInstance() {
    this.instance = axios.create({
      headers: {
        'Content-Type': 'application/json',
      },
      timeout: 1000 * 60 * 60 * 24,
    });
  }

  setInterceptors(unauthorizedHandler) {
    if (unauthorizedHandler) {
      this.instance.interceptors.response.use(undefined, (error) => {
        // work around for https://github.com/axios/axios/issues/441
        const res = error.response;

        if (res.status === 401) {
          unauthorizedHandler(res);
        }
        return Promise.reject(error);
      });
    }
    this.instance.interceptors.request.use((config) => {
      if (this.token) {
        // eslint-disable-next-line no-param-reassign
        config.headers.common['x-access-token'] = this.token;
      }
      return config;
    });
  }

  initToken() {
    const token = localStorage.getItem('user-token');

    if (token) {
      this.setToken(token);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  setToken(token) {
    BaseAPIClient.prototype.token = token;
  }
}
