/* eslint no-shadow: ["error", { "allow": ["state"] }] */

import { uniq } from 'lodash';
import { format } from '@/utils/dates';
import reportsClient from '@/services/reportsClient';

import {
    DAILY_REPORTS_REQUEST,
    DAILY_REPORTS_SUCCESS,
    DAILY_REPORTS_ERROR,
    ACTIVITY_REPORTS_REQUEST,
    ACTIVITY_REPORTS_SUCCESS,
    ACTIVITY_REPORTS_ERROR,
    USERNAMES_REQUEST,
    USERNAMES_SUCCESS,
    USERNAMES_ERROR,
    GROUPS_REQUEST,
    GROUPS_SUCCESS,
    GROUPS_ERROR,
} from '../actions/reports';

const state = {
    usernames: [],
    groups: [],
    dailyReports: [],
    activityReports: [],
    error: '',
    status: '',
};

const getters = {
    usernames: (state) => state.usernames,
    groups: (state) => state.groups,
    dailyReports: (state) => state.dailyReports,
    activityReports: (state) => state.activityReports,
    isLoading: (state) => state.status === 'loading',
};

const actions = {
    [DAILY_REPORTS_REQUEST]: ({ commit }, payload) => {
        commit(DAILY_REPORTS_REQUEST);

        return reportsClient.getDailyReports(payload)
            .then((response) => commit(DAILY_REPORTS_SUCCESS, response.data))
            .catch((error) => commit(DAILY_REPORTS_ERROR, error));
    },

    [ACTIVITY_REPORTS_REQUEST]: ({ commit }, datesRangeParam) => {
        commit(ACTIVITY_REPORTS_REQUEST);

        return reportsClient.getActivityReports(datesRangeParam)
            .then((response) => commit(ACTIVITY_REPORTS_SUCCESS, response.data))
            .catch((error) => commit(ACTIVITY_REPORTS_ERROR, error));
    },

    [USERNAMES_REQUEST]: ({ commit }, datesRangeParam) => {
        commit(USERNAMES_REQUEST);

        return reportsClient.getUsernames(datesRangeParam)
            .then((response) => commit(USERNAMES_SUCCESS, response.data))
            .catch((error) => commit(USERNAMES_ERROR, error));
    },

    [GROUPS_REQUEST]: ({ commit }, datesRangeParam) => {
        commit(GROUPS_REQUEST);

        return reportsClient.getGroups(datesRangeParam)
            .then((response) => commit(GROUPS_SUCCESS, response.data))
            .catch((error) => commit(GROUPS_ERROR, error));
    },
};

const loadingMutation = (state) => {
    state.status = 'loading';
};

const errorMutation = (state, error) => {
    state.error = error.message;
    state.status = 'error';
    throw error;
};

const mutations = {
    [DAILY_REPORTS_REQUEST]: loadingMutation,
    [ACTIVITY_REPORTS_REQUEST]: loadingMutation,
    [USERNAMES_REQUEST]: loadingMutation,
    [GROUPS_REQUEST]: loadingMutation,

    [DAILY_REPORTS_SUCCESS]: (state, results) => {
        const reports = results.map((item) => ({
        ...item,
        firstQuery: format(item.firstQuery),
        lastQuery: format(item.lastQuery),
        }));
        state.dailyReports = reports;
        state.status = 'success';
    },

    [ACTIVITY_REPORTS_SUCCESS]: (state, results) => {
        const reports = results.map((item) => ({
            ...item,
            firstQuery: format(item.firstQuery),
            lastQuery: format(item.lastQuery),
        }));
        state.activityReports = reports;
        state.status = 'success';
    },

    [USERNAMES_SUCCESS]: (state, results) => {
        const usernames = results.map((result) => result.username);
        state.usernames = uniq(usernames);
        state.status = 'success';
    },

    [GROUPS_SUCCESS]: (state, results) => {
        const groups = results.map((result) => result.group);
        state.groups = uniq(groups);
        state.status = 'success';
    },

    [DAILY_REPORTS_ERROR]: errorMutation,
    [ACTIVITY_REPORTS_ERROR]: errorMutation,
    [USERNAMES_ERROR]: errorMutation,
    [GROUPS_ERROR]: errorMutation,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
