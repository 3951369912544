import prettyBytes from "pretty-bytes";
import moment from "moment";
import Vue from "vue";
// import { creatApp } from 'vue'
import Vuex from "vuex";
import LvInput from "lightvue/input";
import LvButton from "lightvue/button";
import { sync } from "vuex-router-sync";
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
import VuejsClipper from "vuejs-clipper";
import VueCookies from "vue-cookies";
// import features from '@/features';
import VueAzureMaps from "vue-azure-maps";
import { AUTH_LOGOUT } from "@/store/actions/auth";
import { ApiClient } from "@/services/apiClient";
import { BatchClient } from "@/services/batchClient";
import { OngoingAPIClient } from "@/services/ongoingClient";
import { IndexAPIClient } from "@/services/indexClient";
import { GeoAPIClient } from "@/services/geoClient";
import { KeywordAPIClient } from "@/services/keywordClient";
import { ReportsClient } from "@/services/reportsClient";
import vuetify from "./plugins/vuetify";
// import { abilitiesPlugin, Can } from '@casl/vue';
// import ability from './ability';
import VueBubbleEvent from "./plugins/bubble";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";
import App from "./App";
import "@/assets/global.scss";
// TODO: Check if we need both
import "@mdi/font/css/materialdesignicons.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "typeface-roboto/index.css";
import { CONFIG_REQUEST } from "./store/actions/config";
import ProgressBar from "@/components/loaders/LoaderLight";
import LoaderSingle from "@/components/loaders/LoaderSingleLine";

// Vue.use(abilitiesPlugin, ability);
Vue.config.productionTip = false;
Vue.use(VuejsClipper);
Vue.prototype.prettyBytes = prettyBytes;
Vue.prototype.moment = moment;
// Vue.prototype.features = features;



const unAuth = () => store.dispatch(AUTH_LOGOUT);
Vue.prototype.$apiClient = new ApiClient(unAuth);
Vue.prototype.$batchClient = new BatchClient(unAuth);
Vue.prototype.$ongoingClient = new OngoingAPIClient(unAuth);
Vue.prototype.$indexClient = new IndexAPIClient(unAuth);
Vue.prototype.$geoClient = new GeoAPIClient(unAuth);
Vue.prototype.$keywordClient = new KeywordAPIClient(unAuth);
Vue.prototype.$reportsClient = new ReportsClient(unAuth);

Vue.use(VueCookies);
Vue.use(VueBubbleEvent);
Vue.use(Vuex);
Vue.component("LvInput", LvInput);
Vue.component("LvButton", LvButton);
Vue.component("ProgressBar", ProgressBar);
Vue.component("LoaderSingle", LoaderSingle);
Vue.component("vue-json-pretty", VueJsonPretty);
Vue.use(VueAzureMaps, {
  key: "5qk7oJwTe8X06oAla6Gtay21T_gqFjQA1mG1xfGFYqM",
});

// Vue.use(abilitiesPlugin);
// Vue.component('Can', Can);

sync(store, router);

const vm = new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
  beforeCreate() {
    store.dispatch(CONFIG_REQUEST);
  },
}).$mount("#app");

export default { vm };
