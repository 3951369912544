/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import configClient from '@/services/configClient';
import { CONFIG_REQUEST, CONFIG_SUCCESS, CONFIG_ERROR } from '../actions/config';

const state = {
  featureFlags: {
    useAdverse: null,
    useBatch: null,
    useImageRecognition: null,
    useOngoing: null,
    useGeoSearch: null,
  },
};

const getters = {
  config: (state) => state.featureFlags,
};

const actions = {
  [CONFIG_REQUEST]: ({ commit }) => {
    configClient.getConfigFile()
      .then((config) => commit(CONFIG_SUCCESS, config))
      .catch((err) => commit(CONFIG_ERROR, err));
  },
};

const mutations = {
  [CONFIG_SUCCESS]: (state, config) => {
    state.featureFlags = config;
  },

  [CONFIG_ERROR]: (state, err) => {
    state.featureFlags = {};
    throw err;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
