export const PIPELINES_REQUEST = 'PIPELINES_REQUEST';
export const PIPELINES_SUCCESS = 'PIPELINES_SUCCESS';
export const PIPELINES_ERROR = 'PIPELINES_ERROR';
export const PIPELINES_INIT = 'PIPELINES_INIT';

export const PIPELINES_RUNNER_REQUEST = 'PIPELINES_RUNNER_REQUEST';
export const PIPELINES_RUNNER_SUCCESS = 'PIPELINES_RUNNER_SUCCESS';
export const PIPELINES_RUNNER_ERROR = 'PIPELINES_RUNNER_ERROR';

export const DELTAS_REQUEST = 'DELTAS_REQUEST';
export const DELTAS_SUCCESS = 'DELTAS_SUCCESS';
export const DELTAS_ERROR = 'DELTAS_ERROR';
export const DELTAS_INIT = 'DELTAS_INIT';

export const JOBS_REQUEST = 'JOBS_REQUEST';
export const JOBS_SUCCESS = 'JOBS_SUCCESS';
export const JOBS_ERROR = 'JOBS_ERROR';
export const JOBS_INIT = 'JOBS_INIT';
