import { merge } from "lodash";
import apiClient from "@/services/apiClient";
import crudHelper from "../helpers/crud";

const userActions = {
  READ: apiClient.getUser.bind(apiClient),
  READ_LIST: apiClient.getUsers.bind(apiClient),
  CREATE: apiClient.addUser.bind(apiClient),
  UPDATE: apiClient.updateUser.bind(apiClient),
  DELETE: apiClient.deleteUser.bind(apiClient),
};

const userStore = crudHelper("user", "username", userActions);

// todo implement fully functional role management
const roleStore = {
  state: {
    actionRoles: ["SUPERVISOR", "ANALYST"],
    adminRoles: ["SYS_ADMIN","ADMIN"],
    roles: ["ADMIN", "CHIEF_SUPERVISOR", "SUPERVISOR", "ANALYST"],
    sysAdmin: ["SYS_ADMIN"],
  },
  getters: {
    roleList: (state) => state.roles,
    allRoles: (state) => [...state.sysAdmin, ...state.roles],
    actionRoles: (state) => state.actionRoles,
    adminRoles: (state) => state.adminRoles
  },
};

const module = merge({ namespaced: true }, userStore, roleStore);
export default module;
