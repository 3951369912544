<template>
  <v-list-item @click="select">
    <v-list-item-icon>
      <v-icon>{{icon}}</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      {{title}}
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  props: {
    route: String,
    title: String,
    icon: String,
    props: {
      type: Object,
      default: () => {},
    },
    bubble: {
      default: true,
      type: Boolean,
    },
  },
  methods: {
    select() {
      if (this.bubble) {
        this.$bubble('itemSelected', this.route, this.props);
      } else {
        this.$emit('itemSelected', this.route, this.props);
      }
    },
  },
};
</script>

<style scoped>

</style>
