import BaseAPIClient from '@/services/baseClient';

export class KeywordAPIClient extends BaseAPIClient {
  createInstance() {
    super.createInstance();
    this.instance.defaults.baseURL = process.env.NODE_ENV === 'production'
      ? '/api/v1/keyword/'
      : process.env.VUE_APP_KEYWORD_API_URI;
  }

  async search(query) {
    return this.instance.post('search', { query });
  }
}

export default new KeywordAPIClient();
