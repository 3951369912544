import axios from "axios";
import BaseAPIClient from "./baseClient";

export class QuerylogSupervisionClient extends BaseAPIClient {
  constructor() {
    super();

    this.instance.defaults.baseURL =
      process.env.NODE_ENV === "production"
        ? "/api/v1/supervision"
        : process.env.VUE_APP_SUPERVISION_API_URI;

    this.instance.defaults.transformResponse = [].concat(
      axios.defaults.transformResponse,
      (data) => data.resultData
    );
  }

  async getManagers(query) {
    console.log('getManagersgetManagers', query);
    query = JSON.stringify(query);
    return this.instance.get(`/managers/${query}`);
  }

  async getSupervisors() {
    const userId = localStorage.getItem('user-id')
    return this.instance.get(`/supervisors/${userId}`);
  }

  async getSupervisor({ _id }) {
    return this.instance.get(`/supervisors/${_id}`);
  }

  async addSupervisor(supervisor) {
    return this.instance.post("/supervisors", supervisor);
  }

  async updateSupervisor({ _id, ...data }) {
    return this.instance.patch(`/supervisors/${_id}`, data);
  }

  async deleteSupervisor({ _id }) {
    return this.instance.delete(`/supervisors/${_id}`);
  }

  async getWorkers(query) {
    console.log('getWorkersgetWorkers', query);
    if (query) {
      query = JSON.stringify(query);
      return this.instance.get(`/workers/${query}`);
    }
    return [];
  }

  async addWorker(worker) {
    return this.instance.post("/workers", worker);
  }

  async updateWorker({ id, ...data }) {
    return this.instance.patch(`/workers/${id}`, data);
  }

  async deleteWorker(query) {
    const { id } = query;
    return this.instance.delete(`/workers/${id}`);
  }

  async getAllQuerylogs() {
    return this.instance.get("/querylogs/sys-admin");
  }

  async getUserQuerylogs(userId , finalStatus, limit = 25 , skip = 0, sortBy = 'timePending', sortDesc = true , workerIdForFilter , time_range ,newer, type) {
    let getRequestString = `querylogs/${userId}?limit=${limit}&skip=${skip}&sortBy=${sortBy}&sortDesc=${sortDesc}`
    if (finalStatus) getRequestString += `&finalStatus=${finalStatus}`
    if (workerIdForFilter) getRequestString += `&workerId=${workerIdForFilter}`
    if (newer !== '')  getRequestString += `&timeframe=${time_range}&newer=${newer}`
    if (type) getRequestString += `&type=${type}`

    return this.instance.get(getRequestString);
  }

  async getQuerylogsForSupervisor(params) {
    return this.instance.get(`/querylogs/supervisor/${params.user}?status=${params.status}`);
  }

  async getStatisticForChiefOrSupervisor(params) {
    return this.instance.get(`/statistics/${params.user}`);
  }

  async getQuerylogsForChiefSupervisor(params) {
    return this.instance.get(`/querylogs/chiefsupervisor/${params.user}?status=${params.status}&tenantId=${params.tenantId}`);
  }

  async addQuerylog() {
    return this.instance.get("/querylogs");
  }

  async updateQuerylog({ action, ...payload }) {
    const validActionTypes = ["assign", "close", "reopen", "submit", "approve"];

    if (!validActionTypes.includes(action)) {
      throw new Error(`Invalid action type: '${action}'`);
    }

    // console.log('PAY ---- LOAD ----', payload);

    const queryIds = payload.querylogs.map((querylog) => querylog._id);
    payload.querylogs = queryIds;
    if (action === "reopen" || action === "submit" || action === "approve") {
      const filesToAdd = [...payload.history.files];
      payload.history.files.length = 0;
      const response = await this.instance.put(`/querylogs/${action}`, payload);

      for (const file in filesToAdd) {
        const formData = new FormData();
        formData.append("files", filesToAdd[file]);
        await this.instance.put(`/querylogs/addFiles/${response.data[0].id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }

      return response;
    } else {
      return this.instance.put(`/querylogs/${action}`, payload);
    }
  }

  async deleteQuerylog(payload) {
    return this.instance.delete("/querylogs", payload);
  }

  async getDailyReport({ userRole, userId }) {
    const validRoles = ["supervisor", "worker"];

    if (!validRoles.includes(userRole)) {
      throw new Error(`Invalid user role: '${userRole}'`);
    }

    return this.instance.get(`/querylogs/report/${userRole}/${userId}`);
  }
}

export default new QuerylogSupervisionClient();
