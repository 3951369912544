import BaseAPIClient from '@/services/baseClient';
// import axios from 'axios';

export class BatchClient extends BaseAPIClient {
  createInstance() {
    super.createInstance();

    this.instance.defaults.baseURL = process.env.NODE_ENV === 'production'
      ? '/api/v1/batch/'
      : process.env.VUE_APP_BATCH_API_URI;
  }

  async getBatches() {
    return this.instance.get('/batches');
  }

  async getBatch({ id }) {
    return this.instance.get(`/batches/${id}`);
  }

  async newBatch(batch) {
    return this.instance.post('/batches', batch);
  }

  async updateBatch(update) {
    const { id } = update;
    return this.instance.put(`/batches/${id}`, update);
  }

  async deleteBatch({ _id }) {
    return this.instance.delete(`/batches/${_id}`);
  }

  async runBatch({ id, type, params }) {
    switch (type) {
      case 'aml': return this.runAmlBatch({ id, resultFormat: params });
      case 'namesCompare': return this.runNamesComapreBatch({ id });
      default: throw new Error('Invalid batch request type');
    }
  }

  async runAmlBatch({ id, resultFormat }) {
    return this.instance.post(`/batches/${id}/runs`, { resultFormat });
  }

  async runNamesComapreBatch({ id }) {
    return this.instance.post(`/batches/${id}/names`);
  }

  async getProgress({ id }) {
    return this.instance.get(`/batches/${id}/progress`);
  }

  async getFiles() {
    return this.instance.get('/files');
  }

  async getFile({ id }) {
    return this.instance.get(`/files/${id}`);
  }

  async newFile({ file, description }) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('description', description);
    return this.instance.post('/files', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  async updateFile(update) {
    const { _id: id } = update;
    return this.instance.put(`/files/:${id}`, update);
  }

  async deleteFile({ _id }) {
    return this.instance.delete(`/files/${_id}`);
  }

  async downloadResult({ id }) {
    return this.instance.get(`/results/${id}/payload`, { responseType: 'blob' });
  }

  async downloadFile({ id }) {
    return this.instance.get(`/files/${id}/payload`, { responseType: 'blob' });
  }
}

export default new BatchClient();
