import QueryItemsGroup from "./QueryItems";
import OngoingItemsGroup from "./OngoingItems";
import BatchItemsGroup from "./BatchItems";
import ReportsItemsGroup from "./ReportsItems";
import DataSourcesItemsGroup from "./DataSourcesItems";
import DashboardItemsGroup from "./DashboardItems";
import UserItemsGroup from "./UserItems";

export default {
  QueryItemsGroup,
  OngoingItemsGroup,
  BatchItemsGroup,
  ReportsItemsGroup,
  DataSourcesItemsGroup,
  DashboardItemsGroup,
  UserItemsGroup,
};
