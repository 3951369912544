import { merge } from "lodash";
import supervisionClient from "@/services/supervisionClient";
import crudHelper from "../helpers/crud";
import {
  QUERYLOGS_FETCH_REQUEST,
  QUERYLOGS_FETCH_SUCCESS,
  QUERYLOGS_FETCH_ERROR,
  QUERYLOGS_SUPERVISOR_FETCH_SUCCESS,
  QUERYLOGS_SUPERVISOR_FETCH_ERROR,
  QUERYLOGS_WORKER_FETCH_SUCCESS,
  QUERYLOGS_WORKER_FETCH_ERROR,
  QUERYLOG_UPDATE_REQUEST,
  QUERYLOG_UPDATE_SUCCESS,
  QUERYLOG_UPDATE_ERROR,
  READ_LIST,
} from "../actions/supervision";

const supervisorStore = {
  state: {
    supervisors: [],
    workers: [],
  },
  getters: {
    supervisors: (state) => state.supervisors,
    workers: (state) => state.workers,
  },
  actions: {
    [READ_LIST]: ({ commit }, query) => {
      supervisionClient.getSupervisors(query).then((resp) => {
        const resultData = resp.data;
        commit(READ_LIST, resultData);
      });
    },
    CREATE: supervisionClient.addSupervisor.bind(supervisionClient),
    UPDATE: supervisionClient.updateSupervisor.bind(supervisionClient),
    DELETE: supervisionClient.deleteSupervisor.bind(supervisionClient),
  },

  mutations: {
    [READ_LIST]: (state, resultData) => {
      state.supervisors = resultData;
    },
  },
};

// const supervisorStore = crudHelper("supervisor", "id", supervisorActions,supervisionState,supervisionGetters,supervisionMutations);
const workerActions = {
  READ_LIST: supervisionClient.getWorkers.bind(supervisionClient),
  CREATE: supervisionClient.addWorker.bind(supervisionClient),
  UPDATE: supervisionClient.updateWorker.bind(supervisionClient),
  DELETE: supervisionClient.deleteWorker.bind(supervisionClient),
};

const workerStore = crudHelper("worker", "id", workerActions);

// const querylogsActions = {
//   READ_LIST: supervisionClient.getQuerylogs.bind(supervisionClient),
//   CREATE: supervisionClient.addQuerylog.bind(supervisionClient),
//   UPDATE: supervisionClient.updateQuerylog.bind(supervisionClient),
//   DELETE: supervisionClient.deleteQuerylog.bind(supervisionClient),
// };

// const querylogsStore = crudHelper('querylog', 'id', querylogsActions);

const querylogsStoreLoadingMutation = (state) => {
  state.status = "loading";
};

const querylogsStoreUpdateListMutation = (state, payload) => {
  state.status = "success";
  const results = payload.data? payload.data : payload;
  let finalStatus = payload.finalStatus ? payload.finalStatus : "";

  if (finalStatus) {
    finalStatus = finalStatus.charAt(0).toUpperCase() + finalStatus.slice(1);
  }

  state[`querylogs${finalStatus}`] = results;
  // state.querylogs = results;
  state.error = null;
  // console.log('AFTER UPDATE ::', `querylogs${finalStatus}`, state[`querylogs${finalStatus}`]);
};

const querylogsUpdateSingleMutation = (state, result) => {
  state.status = "success";

  const idx = state.querylogs.findIndex((querylog) => querylog.id === result.id);
  state.querylogs.splice(idx, 1, result);

  state.error = null;
};

const querylogsStoreErrorMutation = (state, error) => {
  state.status = "error";
  state.error = error;
  state.querylogs = [];
};

const querylogsStore = {
  state: {
    querylogs: [],
    querylogsPending: [],
    querylogsOpen: [],
    querylogsReopened: [],
    querylogsClosed: [],
    querylogsSubmitted: [],

    status: "",
    error: null,
  },
  getters: {
    querylogs: (state) => state.querylogs,
    querylogsPending: (state) => state.querylogsPending,
    querylogsOpened: (state) => state.querylogsOpen,
    querylogsReopened: (state) => state.querylogsReopened,
    querylogsClosed: (state) => state.querylogsClosed,
    querylogsSubmitted: (state) => state.querylogsSubmitted,
    isLoading: (state) => state.status === "loading",
    getFinalStatus: (state) => state.finalstatus,
    status: (state) => state.status,
  },

  actions: {
    [QUERYLOGS_FETCH_REQUEST]: ({ commit }) => {
      commit(QUERYLOGS_FETCH_REQUEST);
      return supervisionClient
        .getAllQuerylogs()
        .then((response) => commit(QUERYLOGS_FETCH_SUCCESS, response.data))
        .catch((err) => commit(QUERYLOGS_FETCH_ERROR, err));
    },

    [QUERYLOG_UPDATE_REQUEST]: ({ commit }, payload) => {
      // ('CHANGES ---- SUPERVISOR STORE', payload);
      commit(QUERYLOG_UPDATE_REQUEST);
      return supervisionClient
        .updateQuerylog(payload)
        .then((response) => {
          // console.log(' ---------- response.data -----------', response.data);
          commit(QUERYLOG_UPDATE_SUCCESS, response.data);
          return response.data;
        })
        .catch((err) => commit(QUERYLOG_UPDATE_ERROR, err));
    },
  },

  mutations: {
    [QUERYLOGS_FETCH_SUCCESS]: querylogsStoreUpdateListMutation,
    [QUERYLOGS_FETCH_ERROR]: querylogsStoreErrorMutation,

    [QUERYLOGS_SUPERVISOR_FETCH_SUCCESS]: querylogsStoreUpdateListMutation,
    [QUERYLOGS_SUPERVISOR_FETCH_ERROR]: querylogsStoreErrorMutation,

    [QUERYLOGS_WORKER_FETCH_SUCCESS]: querylogsStoreUpdateListMutation,
    [QUERYLOGS_WORKER_FETCH_ERROR]: querylogsStoreErrorMutation,

    [QUERYLOG_UPDATE_REQUEST]: querylogsStoreLoadingMutation,
    [QUERYLOG_UPDATE_SUCCESS]: querylogsUpdateSingleMutation,
    [QUERYLOG_UPDATE_ERROR]: querylogsStoreErrorMutation,
  },
};

export default merge({ namespaced: true }, supervisorStore, workerStore, querylogsStore);
