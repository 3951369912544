<template>
  <div>
    <v-app-bar app dense color="primary" dark :clipped-left="true">
      <v-app-bar-nav-icon @click="toggleDrawer" />
      <v-toolbar-title style="cursor: default">
        Fincom AML Platform
      </v-toolbar-title>
      <div class="flex-grow-1"></div>
      <div class="mx-3" style=" cursor: default">
        <v-icon>face</v-icon>
        <span class="text-lowercase">
          {{ userRole }}
        </span>
      </div>
      <v-chip outlined v-if="userName" to="/profile">
        <v-icon>person</v-icon>
        {{ userName }}
      </v-chip>
      <v-btn v-if="isAuthenticated" icon @click="logout">
        <v-icon>logout</v-icon>
      </v-btn>
    </v-app-bar>
    <MainDrawer />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { AUTH_LOGOUT } from "@/store/actions/auth";
import MainDrawer from "@/components/menu/MainDrawer";

export default {
  components: {
    MainDrawer,
  },
  computed: {
    ...mapGetters(["isAuthenticated", "userName", "userRole"]),
  },
  watch: {
    isAuthenticated(newValue) {
      if (!newValue) {
        this.login();
      }
    },
  },
  methods: {
    ...mapActions({ logout: AUTH_LOGOUT, toggleDrawer: "nav/toggleDrawer" }),
    login() {
      this.$router.push("/login");
    },
  },
};
</script>
