import Vue from "vue";
import Vuex from "vuex";
import auth from "@/store/modules/auth";
import search from "@/store/modules/search";
import queryLog from "@/store/modules/queryLog";
import batch from "@/store/modules/batch";
import common from "@/store/modules/common";
import users from "@/store/modules/users";
import tenants from "@/store/modules/tenants";
import nav from "@/store/modules/nav";
import customers from "@/store/modules/customers";
import pipelines from "@/store/modules/pipelines";
import config from "@/store/modules/config";
import reports from "@/store/modules/reports";
import geocheck from "@/store/modules/geocheck";
import supervision from "@/store/modules/supervision";
import { supervisorsStore } from "./supervisorStore/supervisor.store";

Vue.use(Vuex);

// const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    auth,
    search,
    queryLog,
    batch,
    users,
    common,
    nav,
    customers,
    pipelines,
    config,
    reports,
    geocheck,
    supervision,
    supervisorsStore,
    tenants,
  },
  // strict: debug,
});
