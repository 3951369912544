<template>
  <v-container fliud>
    <v-row>
      <v-col cols="12">
        <v-row align="center" justify="center">
          <v-alert border="right"
                   colored-border
                   type="error"
                   elevation="2"
                   :value="hasError"
          >
            Authorization was not successful. Please check your credentials and try again.
          </v-alert>
        </v-row>
        <v-row align="center" justify="center">
          <v-card class="elevation-12" min-width="250" max-width="500" width="100%">
            <v-toolbar
              color="primary"
              dark
              flat
            >
              <v-toolbar-title>Sign-in</v-toolbar-title>
            </v-toolbar>
            <v-form @submit.prevent="login" ref="loginForm">
              <v-card-text>
                <v-text-field label="Login"
                              name="login"
                              v-model.trim="username"
                              type="text"
                              :rules="[rules.required]"
                              prepend-icon="person"
                              hint="Please enter login"
                >

                </v-text-field>
                <v-text-field label="Password"
                              id="password"
                              name="password"
                              v-model.trim="password"
                              type="password"
                              :rules="[rules.required]"
                              prepend-icon="lock"
                >
                  Please enter password
                </v-text-field>
              </v-card-text>
              <v-card-actions class="justify-center">
                <v-btn min-width="100" type="submit" color="primary">Login</v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import jwt from 'jsonwebtoken';
import { mapGetters, mapActions } from 'vuex';
import { AUTH_REQUEST, AUTH_LOGOUT } from '@/store/actions/auth';
import { required, minLen } from '@/utils/validations';
// import { required } from '@/utils/validations';

export default {
  name: 'LoginForm',
  computed: {
    ...mapGetters(['hasError']),
  },
  data() {
    return {
      password: '',
      username: '',
      azureMarketToken: '',
      rules: {
        required,
        minLen,
      },
    };
  },
  methods: {
    ...mapActions({ doLogin: AUTH_REQUEST, doLogout: AUTH_LOGOUT }),
    async login() {
      const { username, password, azureMarketToken } = this;

      if (this.$refs.loginForm.validate()) {
        try {
          await this.doLogin({
            username,
            password,
            azureMarketToken,
          });
          await this.$router.push('/search');
        } catch (e) {
          // Do nothing
        }
      }
    },
  },
  created() {
    const azureToken = this.$cookies.get('amlAIT') || '';
    this.azureMarketToken = this.$cookies.get('amlAMToken') || '';

    try {
      const decodedAmlAITJwt = jwt.decode(azureToken);
      this.username = decodedAmlAITJwt.email || decodedAmlAITJwt.unique_name || '';
    } catch (e) {
      this.username = '';
    }
  },
};
</script>

<style scoped>

</style>
