/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import { SET_DRAWER } from '@/store/actions/nav';

const state = {
  drawer: {
    opened: false,
  },
};

const getters = {
  isDrawerOpen: (state) => state.drawer.opened,
};

const actions = {
  openDrawer: ({ commit }) => {
    commit(SET_DRAWER, true);
  },
  closeDrawer: ({ commit }) => {
    commit(SET_DRAWER, false);
  },

  toggleDrawer: ({ commit, state }) => {
    commit(SET_DRAWER, !state.drawer.opened);
  },
};

const mutations = {
  [SET_DRAWER]: (state, opened) => {
    state.drawer.opened = opened;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
