<template>
  <v-list>
    <DrawerItem title="AML Search" route="/query" icon="search" />
    <!-- <DrawerItem
      title="Adverse media"
      route="/ad-media/search"
      icon="search"
      v-if="this.features.useAdverse"
    />
    <DrawerItem
      title="Geo check"
      route="/address/search"
      icon="map"
      v-if="this.features.useGeoSearch"
    /> -->
    <DrawerItem
      title="CRM search"
      route="/crm/search"
      icon="search"
      v-if="this.features.useGeoSearch"
    />
    <DrawerItem title="Compare names" route="/compare-names" icon="compare_arrows" />
    <DrawerItem
      v-if="isUserAdmin() || isUserSystemAdmin()"
      title="Query log"
      route="/query-log"
      icon="list"
    />
  </v-list>
</template>

<script>
import DrawerItem from "../DrawerItem";

export default {
  components: { DrawerItem },
  props: {
    features: Object,
    userRole: String,
  },
  methods: {
    isUserSystemAdmin() {
      return this.userRole === "SYS_ADMIN";
    },
    isUserAdmin() {
      return this.userRole === "ADMIN";
    },
  },
};
</script>
