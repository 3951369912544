/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import { min, max } from "lodash";
import apiClient from "@/services/apiClient";

import { authorize } from "@/store/helpers/auth";
import {
  SEARCH_ERROR,
  SEARCH_REQUEST,
  SEARCH_RESET,
  SEARCH_SUCCESS,
  GEO_SEARCH_REQUEST,
} from "../actions/search";
const state = {
  query: "",
  result: [],
  status: "",
  hasLoadedOnce: false,
  error: null,
};

const getThresholdArray = (state) => state.result.map((item) => item.max_score);

const getters = {
  searchResult: (state) => state.result,
  minThreshold: (state) => min(getThresholdArray(state)),
  maxThreshold: (state) => max(getThresholdArray(state)),
  isLoading: (state) => state.status === "loading",
  hasLoadedOnce: (state) => state.hasLoadedOnce,
  query: (state) => state.query,
  error: (state) => state.error,
};

const actions = {
  [SEARCH_REQUEST]: ({ commit, dispatch }, query) => {
    commit(SEARCH_REQUEST, query);
    apiClient
      .search(query)
      .then((resp) => {
        const resultData = resp.data;
        commit(SEARCH_SUCCESS, resultData);
      })
      .catch((err) => {
        authorize(err, dispatch);
        commit(SEARCH_ERROR, err);
      });
  },
  [GEO_SEARCH_REQUEST]: ({ commit, dispatch }, query) => {
    commit(SEARCH_REQUEST, query);
    apiClient
      .geoSearch(query)
      .then((response) => {
        commit(SEARCH_SUCCESS, response.data);
      })
      .catch((err) => {
        authorize(err, dispatch);
        commit(SEARCH_ERROR, err);
      });
  },

  [SEARCH_RESET]: ({ commit }) => {
    commit(SEARCH_RESET);
  },
};

const mutations = {
  [SEARCH_REQUEST]: (state, query) => {
    state.status = "loading";
    state.query = query;
  },
  [SEARCH_SUCCESS]: (state, resp) => {
    state.status = "success";
    state.result = resp || [];
    state.hasLoadedOnce = true;
    state.error = null;
  },
  [SEARCH_ERROR]: (state, err) => {
    state.status = "error";
    state.hasLoadedOnce = true;
    state.error = err;
  },
  [SEARCH_RESET]: (state) => {
    state.query = "";
    state.status = "";
    state.result = [];
    state.hasLoadedOnce = false;
    state.error = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
