<template>
  <v-list-group
    v-if="
      isUserSystemAdmin() ||
      isUserAdmin() ||
      isUserSupervisor() ||
      isUserWorker() ||
      isUserChiefSupervisor()
    "
  >
    <template v-slot:activator>
      <v-list-item-icon>
        <v-icon>person_add</v-icon>
      </v-list-item-icon>
      <v-list-item-title>User managment</v-list-item-title>
    </template>

    <v-divider />

    <DrawerItem
      v-if="isUserSystemAdmin() || isUserAdmin()"
      title="User managment"
      route="/admin/user-management"
      icon="keyboard_arrow_right"
    />

    <DrawerItem
      v-if="isUserSystemAdmin() || isUserAdmin()"
      title="Tenants"
      route="/admin/tenant-management"
      icon="keyboard_arrow_right"
    />

    <v-divider />
  </v-list-group>
</template>

<script>
import DrawerItem from "../DrawerItem";

export default {
  components: { DrawerItem },
  props: {
    userRole: String,
  },
  computed: {
    normalizedRole() {
      return this.userRole.toLowerCase();
    },
  },
  methods: {
    isUserSystemAdmin() {
      return this.userRole === "SYS_ADMIN";
    },
    isUserAdmin() {
      return this.userRole === "ADMIN";
    },
    isUserChiefSupervisor() {
      return this.userRole === "CHIEF_SUPERVISOR";
    },
    isUserSupervisor() {
      return this.userRole === "SUPERVISOR";
    },
    isUserWorker() {
      return this.userRole === "ANALYST";
    },
  },
};
</script>
