/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import apiClient from "../../services/apiClient";

import { AUTH_REQUEST, AUTH_ERROR, AUTH_SUCCESS, AUTH_LOGOUT } from "../actions/auth";

const state = {
  token: localStorage.getItem("user-token") || "",
  role: localStorage.getItem("user-role") || "",
  userName: localStorage.getItem("user-name") || "",
  userId: localStorage.getItem("user-id") || "",
  tenantId: localStorage.getItem("tenantId") || "",
  userProfile: null,
  status: "",
  hasLoadedOnce: false,
  errors: null,
  managerId: "",
};

const getters = {
  isAuthenticated: (state) =>
    !!(state.token && state.token !== "undefined" && state.token !== "" && state.token !== null),
  authStatus: (state) => state.status,
  hasError: (state) => state.status === "error",
  userRole: (state) => state.role,
  userName: (state) => state.userName,
  userId: (state) => state.userId,
  tenantId: (state) => state.tenantId,
  managerId: (state) => state.managerId,
  userGroup: (state) => state.userGroup,
  errors: (state) => state.errors,
};

const actions = {
  [AUTH_REQUEST]: ({ commit }, user) =>
    new Promise((resolve, reject) => {
      commit(AUTH_REQUEST);
      apiClient
        .authenticate(user)
        .then((resp) => {
          const resultData = resp.data;
          commit(AUTH_SUCCESS, resultData);
          resolve(resp);
        })
        .catch((err) => {
          commit(AUTH_ERROR, err);
          reject(err);
        });
    }),
  // eslint-disable-next-line no-unused-vars
  [AUTH_LOGOUT]: ({ commit }) =>
    new Promise((resolve) => {
      localStorage.clear();
      apiClient.logout().finally(() => {
        commit(AUTH_LOGOUT);
        resolve();
      });
    }),
};

const mutations = {
  [AUTH_REQUEST]: (state) => {
    state.status = "loading";
  },
  [AUTH_SUCCESS]: (state, resp) => {
    const { tenantId } = resp;
    const defaultDateFormat = 'MM-DD-YYYY HH:mm:ss'
    const defaultTimezone = 'America/Chicago'
    const timeFrameInMinutes = 180 //default 3 hours

    if (!resp.tenantOptions) {
      localStorage.setItem('dateFormat', defaultDateFormat);
      localStorage.setItem('tenantId', tenantId);
      localStorage.setItem('timezone', defaultTimezone);
      localStorage.setItem('timeFrameInMinutes', timeFrameInMinutes);
    } else {
      const { tenantOptions } = resp || {};
      localStorage.setItem('dateFormat', tenantOptions?.dateFormat || defaultDateFormat);
      localStorage.setItem('tenantId', tenantId || 'no-tenant');
      localStorage.setItem('timezone', tenantOptions?.timezone || defaultTimezone);
      localStorage.setItem('timeFrameInMinutes', tenantOptions?.timeFrameInMinutes || timeFrameInMinutes);
    }

    localStorage.setItem("user-token", resp.token);
    localStorage.setItem("user-role", resp.role);
    localStorage.setItem("user-name", resp.username);
    localStorage.setItem("user-id", resp.id);
    apiClient.setToken(resp.token);

    state.status = "success";
    state.token = resp.token;
    state.role = resp.role;
    state.userName = resp.username;
    state.userProfile = resp;
    state.hasLoadedOnce = true;
    state.tenantId = resp.tenantId;
  },
  [AUTH_ERROR]: (state, err) => {
    state.status = "error";
    state.errors = err.message;
    state.hasLoadedOnce = true;
  },
  [AUTH_LOGOUT]: (state) => {
    localStorage.clear();

    state.userProfile = null;
    state.token = "";
    state.role = "";
    state.userName = "";
    state.status = "";
    state.tenantId = "";
    state.errors = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
