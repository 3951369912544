/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import ongoingClient from '@/services/ongoingClient';

import { authorize } from '@/store/helpers/auth';
import {
  PIPELINES_REQUEST,
  PIPELINES_SUCCESS,
  PIPELINES_ERROR,
  PIPELINES_RUNNER_REQUEST,
  PIPELINES_RUNNER_SUCCESS,
  PIPELINES_RUNNER_ERROR,
  PIPELINES_INIT,
  DELTAS_REQUEST,
  DELTAS_SUCCESS,
  DELTAS_ERROR,
  // DELTAS_INIT,
  JOBS_REQUEST,
  JOBS_SUCCESS,
  JOBS_ERROR,
  // JOBS_INIT,
} from '@/store/actions/pipelines';

const state = {
  pipelineResult: [],
  deltaResult: [],
  jobsResult: [],
};

const getters = {
  pipelineGetResult: (state) => state.pipelineResult,
  deltaGetResult: (state) => state.deltaResult,
  jobsGetResult: (state) => state.jobsResult,
  isLoading: (state) => state.status === 'loading',
  hasLoadedOnce: (state) => state.hasLoadedOnce,
  noMore: (state) => state.noMore,
};

const actions = {
  [PIPELINES_REQUEST]: ({ commit, dispatch }) => {
    commit(PIPELINES_REQUEST);
    return ongoingClient.getPipelines()
      .then(({ data }) => {
        commit(PIPELINES_SUCCESS, data);
      })
      .catch((err) => {
        authorize(err, dispatch);
        commit(PIPELINES_ERROR, err);
      });
  },
  [PIPELINES_RUNNER_REQUEST]: ({ commit, dispatch }, items) => {
    commit(PIPELINES_RUNNER_REQUEST);
    const ids = items.map((item) => item.id);
    return ongoingClient.runPipeline(ids)
      .then(({ data }) => {
        commit(PIPELINES_RUNNER_SUCCESS, data);
      })
      .catch((err) => {
        authorize(err, dispatch);
        commit(PIPELINES_RUNNER_ERROR, err);
      });
  },
  [DELTAS_REQUEST]: ({ commit, dispatch }) => {
    commit(DELTAS_REQUEST);
    return ongoingClient.getDeltas()
      .then(({ data }) => {
        commit(DELTAS_SUCCESS, data);
      })
      .catch((err) => {
        authorize(err, dispatch);
        commit(DELTAS_ERROR, err);
      });
  },
  [JOBS_REQUEST]: ({ commit, dispatch }) => {
    commit(JOBS_REQUEST);
    return ongoingClient.getJobs()
      .then(({ data }) => {
        commit(JOBS_SUCCESS, data);
      })
      .catch((err) => {
        authorize(err, dispatch);
        commit(JOBS_ERROR, err);
      });
  },
  [PIPELINES_INIT]: ({ commit }) => {
    commit(PIPELINES_INIT);
  },
  // [DELTAS_INIT]: ({ commit }) => {
  //   commit(DELTAS_INIT);
  // },
  // [JOBS_INIT]: ({ commit }) => {
  //   commit(JOBS_INIT);
  // },
};

const loadingMutation = (state) => {
  state.status = 'loading';
};

const errorMutation = (state) => {
  state.status = 'error';
  state.hasLoadedOnce = true;
};

const mutations = {
  [PIPELINES_REQUEST]: loadingMutation,
  [PIPELINES_SUCCESS]: (state, data) => {
    if (data.length) {
      state.pipelineResult = data;
    }
  },

  [PIPELINES_ERROR]: errorMutation,

  [PIPELINES_RUNNER_REQUEST]: loadingMutation,

  [PIPELINES_RUNNER_ERROR]: errorMutation,

  [DELTAS_REQUEST]: loadingMutation,

  [DELTAS_SUCCESS]: (state, data) => {
    if (data.length) {
      state.deltaResult = data;
    }
  },

  [DELTAS_ERROR]: errorMutation,

  [JOBS_REQUEST]: loadingMutation,

  [JOBS_SUCCESS]: (state, data) => {
    if (data.length) {
      state.jobsResult = data;
    }
  },

  [JOBS_ERROR]: errorMutation,
  [PIPELINES_INIT]: (state) => {
    state.pipelineResult = [];
    state.deltaResult = [];
    state.jobsResult = [];
  },
  // [DELTAS_INIT]: (state) => {
  //   state.deltaResult = [];
  // },
  // [JOBS_INIT]: (state) => {
  //   state.jobsResult = [];
  // },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
