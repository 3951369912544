<template>
  <v-app>
    <Navigation />
    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
    <v-footer app>
      <v-col class="text-center caption pa-0 font-weight-thin" cols="12" padless
      >Copyright © 2016-{{ new Date().getFullYear() }} FinCom.co All rights reserved.
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import Navigation from "@/components/Navigation.vue";

export default {
  name: "App",
  components: { Navigation },
};
</script>
