export const QUERY_LOG_REQUEST = "QUERY_LOG_REQUEST";
export const QUERY_LOG_SUCCESS = "QUERY_LOG_SUCCESS";
export const QUERY_LOG_ERROR = "QUERY_LOG_ERROR";
export const QUERY_LOG_INIT = "QUERY_LOG_INIT";

export const REPORT_DASHBOARD_STATISTICS_DATA = "REPORT_DASHBOARD_STATISTICS_DATA";

export const QUERY_LOG_DELETE_REQUEST = "QUERY_LOG_DELETE_REQUEST";
export const QUERY_LOG_DELETE_SUCCESS = "QUERY_LOG_DELETE_SUCCESS";
export const QUERY_LOG_DELETE_ERROR = "QUERY_LOG_DELETE_ERROR";

export const QUERY_LOG_RESET_REQUEST = "QUERY_LOG_RESET_REQUEST";
export const QUERY_LOG_RESET_SUCCESS = "QUERY_LOG_RESET_SUCCESS";
export const QUERY_LOG_RESET_ERROR = "QUERY_LOG_RESET_ERROR";

export const QUERY_LOG_REFRESH_REQUEST = "QUERY_LOG_REFRESH_REQUEST";
export const QUERY_LOG_REFRESH_SUCCESS = "QUERY_LOG_REFRESH_SUCCESS";
export const QUERY_LOG_REFRESH_ERROR = "QUERY_LOG_REFRESH_ERROR";

export const QUERY_LOG_UPDATE_REQUEST = "QUERY_LOG_UPDATE_REQUEST";
export const QUERY_LOG_UPDATE_SUCCESS = "QUERY_LOG_UPDATE_SUCCESS";
export const QUERY_LOG_UPDATE_ERROR = "QUERY_LOG_UPDATE_ERROR";

export const QUERY_LOG_FETCH_ITEM_REQUEST = "QUERY_LOG_FETCH_ITEM_REQUEST";
export const QUERY_LOG_SAVE_TABLE = "QUERY_LOG_FETCH_SAVE_TABLE";
export const QUERY_LOG_FETCH_ITEM_SUCCESS = "QUERY_LOG_FETCH_ITEM_SUCCESS";
export const QUERY_LOG_FETCH_ITEM_ERROR = "QUERY_LOG_FETCH_ITEM_ERROR";
