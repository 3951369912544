<template>
  <transition name="fade">
    <div class="loader">
      <div class="progressbar">
        <LvProgressBar mode="indeterminate" />
        <br />
        <div class="text-center button">LOADING DATA...</div>
        <br />
        <LvProgressBar mode="indeterminate" color="#38b2ac" />
      </div>
    </div>
  </transition>
</template>

<script>
import LvProgressBar from "lightvue/progress-bar";

export default {
  data() {
    return {};
  },
  components: {
    LvProgressBar: LvProgressBar,
  },
};
</script>

<style scoped>
.loader {
  position: absolute;
  width: 96%;
  height: 80%;
  background: #fff;
  z-index: 9999;
}
.progressbar {
  opacity: 1;
  position: relative;
  left: auto;
  top: 10rem;
}
</style>
