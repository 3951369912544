export const required = (value) => !!value || 'Required field';

export const minLen = (len) => (value) => value?.length >= len || `Field require ${len} or more symbols`;

export const maxLen = (len) => (value) => value?.length <= len || `Field require ${len} or less symbols`;

export const maxItems = (amount) => (items = []) => items.length <= amount || `Can only hold up to ${amount} items`;

export const email = (value) => {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return !!re.test(value) || 'Email is not valid';
};

// this function doesn't return an optional string (which is a truthy value)
export const email2 = (value) => {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return !!re.test(value);
};
