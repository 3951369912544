import supervisionClient from "../../services/supervisionClient";

export const supervisorsStore = {
  state: {
    supervisors: [],
    supervisorFilter: null,
    workers: [],
  },
  getters: {
    workersToShow(state) {
      let workers = state.workers;
      let filterBy = state.supervisorFilter;
      workers = workers.filter((worker) => {
        if (filterBy) {
          return worker.managerId.includes(filterBy);
        }
        return worker;
      });
      return workers;
    },
    workers(state) {
      return state.workers;
    },
    supervisors(state) {
      return state.supervisors;
    },
  },
  mutations: {
    setSupervisors(state, { data }) {
      state.supervisors = data;
      state.supervisors.forEach((supervisor) => {
        delete supervisor.password;
      });
    },
    setWorkers(state, { data }) {
      state.workers = data;
    },
    setFilter(state, { supervisorId }) {
      state.supervisorFilter = supervisorId;
    },
  },
  actions: {
    async setSupervisors({ commit }, payload) {
      // xxxxxx
      console.log('SUPERVISOR SET:', payload);

      const { data } = await supervisionClient.getSupervisors(payload);
      //console.log('data setSUPERVISOR supervisor.store:', data);      
      commit({ type: "setSupervisors", data });
    },
    // eslint-disable-next-line no-empty-pattern
    async createSupervisor({}, { query }) {
      await supervisionClient.addSupervisor(query);
    },
    // eslint-disable-next-line no-empty-pattern
    async updateSupervisor({}, { query }) {
      await supervisionClient.updateSupervisor(query);
    },
    async setSupervisorFilter({ commit }, { supervisorId }) {
      commit({ type: "setFilter", supervisorId });
    },
    // eslint-disable-next-line no-empty-pattern
    async deleteSupervisor({}, { query }) {
      await supervisionClient.deleteSupervisor(query);
    },
    // eslint-disable-next-line no-empty-pattern
    async getSupervisorWorkers({}, { supervisor }) {
      const query = { managerId: supervisor };
      const { data } = await supervisionClient.getWorkers(query);
      return data;
    },
    async setWorkers({ commit }, payload) {
      console.log('setworkers---payload', payload);
      // const supervisorId = payload.managerId;
      // const query = { managerId: supervisorId };
      const { data } = await supervisionClient.getWorkers(payload);
      console.log('data setWorkers supervisor.store:', data);
      commit({ type: "setWorkers", data });
    },
    // eslint-disable-next-line no-empty-pattern
    async createWorker({}, { query }) {
      await supervisionClient.addWorker(query);
    },
    // eslint-disable-next-line no-empty-pattern
    async updateWorker({}, { query }) {
      await supervisionClient.updateWorker(query);
    },
    // eslint-disable-next-line no-empty-pattern
    async deleteWorker({}, { query }) {
      await supervisionClient.deleteWorker(query);
    },
  },
};
