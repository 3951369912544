export const DAILY_REPORTS_REQUEST = 'DAILY_REPORTS_REQUEST';
export const DAILY_REPORTS_SUCCESS = 'DAILY_REPORTS_SUCCESS';
export const DAILY_REPORTS_ERROR = 'DAILY_REPORTS_ERROR';

export const ACTIVITY_REPORTS_REQUEST = 'ACTIVITY_REPORTS_REQUEST';
export const ACTIVITY_REPORTS_SUCCESS = 'ACTIVITY_REPORTS_SUCCESS';
export const ACTIVITY_REPORTS_ERROR = 'ACTIVITY_REPORTS_ERROR';

export const USERNAMES_REQUEST = 'USERNAMES_REQUEST';
export const USERNAMES_SUCCESS = 'USERNAMES_SUCCESS';
export const USERNAMES_ERROR = 'USERNAMES_ERROR';

export const GROUPS_REQUEST = 'GROUPS_REQUEST';
export const GROUPS_SUCCESS = 'GROUPS_SUCCESS';
export const GROUPS_ERROR = 'GROUPS_ERROR';
