<template>
  <v-navigation-drawer
    app
    clipped
    left
    :value="isDrawerOpen"
    @input="onInput"
    @itemSelected="routeTo"
  > 
    <v-list>
      <DrawerItem title="Home page" route="/" icon="home" />

      <QueryItemsGroup :userRole="userRole" :features="features" />
      <!-- <OngoingItemsGroup :features="features" /> -->
      <BatchItemsGroup :features="features" />
      <ReportsItemsGroup v-if="isUserSystemAdmin()"  :userRole="userRole" />
      <DataSourcesItemsGroup v-if="isUserSystemAdmin()" />

      <UserItemsGroup v-if="this.$store.getters['users/adminRoles'].includes(userRole)" :userRole="userRole" />
      
      <DashboardItemsGroup v-if="!this.$store.getters['users/adminRoles'].includes(userRole)" :userRole="userRole" />

      <DrawerItem
            v-if="['CHIEF_SUPERVISOR'].includes(userRole)"
            title="User management"
            route="/supervision/users"
            icon="supervisor_account"
        />

       <!-- <DrawerItem
        title="API Docs"
        route="/api/v1/docs"
        icon="school"
        @itemSelected="redirect()"
        :bubble="false"
      /> -->

      <DrawerItem title="Logout" route="/api/v1/docs" icon="logout" @itemSelected="logout" />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { AUTH_LOGOUT } from "@/store/actions/auth";
import { SET_DRAWER } from "@/store/actions/nav";
import DrawerItem from "@/components/menu/DrawerItem";
import ListGroups from "./groups";

export default {
  components: { DrawerItem, ...ListGroups },
  computed: {
    ...mapGetters({
      userRole: "userRole",
      userId: "userRole",
      features: "config",
      isDrawerOpen: "nav/isDrawerOpen",
      alertedCustomers: "alertedCustomers",
    }),
  },
  methods: {
    ...mapActions({ logout: AUTH_LOGOUT }),
    ...mapMutations({ setDrawer: `nav/${SET_DRAWER}` }),
    isUserSystemAdmin() {
      return this.userRole === "SYS_ADMIN";
    },
    isUserAdmin() {
      return this.userRole === "ADMIN";
    },
    routeTo(page, props) {
      if (this.$router.currentRoute.path !== page) {
        this.$router.push({ path: page, params: props });
      }
    },
    redirect() {
      this.$apiClient.openDocs();
    },
    onInput(e) {
      this.setDrawer(e);
    },
  },
};
</script>

<style>
.v-list-item--active {
  background: #161a4f !important;
  color: #fff !important;
}
</style>
