<template>
    <v-list-group>

        <template v-slot:activator>
            <v-list-item-icon>
                <v-icon>description</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Case Mgmt.</v-list-item-title>
        </template>

        <v-divider />
        <!--
        <DrawerItem
            v-if="['SYS_ADMIN', 'ADMIN', 'CHIEF_SUPERVISOR'].includes(userRole)"
            title="Team management"
            route="/supervision/users"
            icon="keyboard_arrow_right"
        />
        -->
        <DrawerItem
            v-if="!this.$store.getters['users/adminRoles'].includes(userRole)"
            title="Querylogs dashboard"
            :route="`/supervision/dashboard/${this.normalizedRole}`"
            icon="keyboard_arrow_right"
        />

    <DrawerItem
      v-if="!this.$store.getters['users/adminRoles'].includes(userRole)"
      title="Reports dashboard"
      :route="`/supervision/report/dashboard/${this.normalizedRole}`"
      icon="keyboard_arrow_right"
    />

        <!--
        <DrawerItem
            v-if="isUserSupervisor() || isUserWorker()"
            title="Create report"
            :route="`/supervision/report/${normalizedRole}`"
            :props="{ userRole: normalizedRole }"
            icon="keyboard_arrow_right"
        />
        -->
    
        <v-divider />
    </v-list-group>
</template>

<script>
import DrawerItem from "../DrawerItem";

export default {
  props: {
        userRole: String,
    },
    components: { DrawerItem },
    computed: {
        normalizedRole() {
            return this.userRole.toLowerCase().replace('_', '');
        }
  },
};
</script>
