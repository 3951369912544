/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import apiClient from '@/services/apiClient';
import geoClient from '@/services/geoClient';
import keywordClient from '@/services/keywordClient';

import { SEARCH_REQUEST, SEARCH_RESET, SEARCH_SUCCESS } from '../actions/geocheck';

const state = {
  results: {
    names: [],
    locations: [],
    keywords: [],
  },
  hasLoadedOnce: false,
  isLoading: false,
};

const getters = {
  amlResults: (state) => state.results.names,
  geoResults: (state) => state.results.locations,
  keywordsResults: (state) => state.results.keywords,
  hasLoadedOnce: (state) => state.hasLoadedOnce,
  isLoading: (state) => state.isLoading,
};

const actions = {
  [SEARCH_REQUEST]: async ({ commit }, params) => {
    const { receiverName, amlQueryParams, address } = params;

    commit(SEARCH_REQUEST);

    const [amlResults, geoResults, receiverResults, addressResults] = await Promise.allSettled(
      [
        apiClient.search({
          names: [receiverName],
          ...amlQueryParams,
        }),
        geoClient.search(address),
        keywordClient.search(receiverName),
        keywordClient.search(address),
      ],
    );

    commit(SEARCH_SUCCESS, {
      amlResults, geoResults, receiverResults, addressResults,
    });
  },
  [SEARCH_RESET]: ({ commit }) => {
    commit(SEARCH_RESET);
  },
};

const mutations = {
  [SEARCH_REQUEST]: (state) => {
    state.isLoading = true;
  },
  [SEARCH_SUCCESS]: (state, results) => {
    state.isLoading = false;
    state.hasLoadedOnce = true;

    const {
      amlResults, geoResults, receiverResults, addressResults,
    } = results;

    state.results.names = amlResults?.value?.data || [];
    state.results.locations = geoResults?.value?.data || [];
    state.results.keywords = [
      ...receiverResults?.value?.data.map((keyword) => ({
        matchedWord: keyword.keyword,
        foundIn: 'Receiver name',
      })),
      ...addressResults?.value?.data.map((keyword) => ({
        matchedWord: keyword.keyword,
        foundIn: 'Address',
      })),
    ];
  },
  [SEARCH_RESET]: (state) => {
    state.isLoading = false;
    state.hasLoadedOnce = false;

    state.results = {
      names: [],
      locations: [],
      keywords: [],
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
