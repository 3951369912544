<template>
  <v-list-group>
    <template v-slot:activator>
      <v-list-item-icon>
        <v-icon>list</v-icon>
      </v-list-item-icon>
      <v-list-item-title>Reports</v-list-item-title>
    </template>

    <v-divider />
    <DrawerItem
      title="Daily report"
      route="/reports/daily"
      icon="keyboard_arrow_right"
    />
    <DrawerItem
      title="Activity report"
      route="/reports/activity"
      icon="keyboard_arrow_right"
      v-if="userRole === 'SYS_ADMIN'"
    />
    <v-divider />
  </v-list-group>
</template>

<script>
import DrawerItem from '../DrawerItem';

export default {
  components: { DrawerItem },
  props: {
    userRole: String,
  },
};
</script>
