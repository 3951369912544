import Vue from "vue";
import Router from "vue-router";
import Login from "@/views/Login";
// import features from '@/features';
import store from "./store";

Vue.use(Router);

// const ifNotAuthenticated = (to, from, next) => {
//   if (!store.getters.isAuthenticated) {
//     next();
//     return;
//   }
//   next('/');
// };

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/login");
};

const fallBackComponent = () => import(/* webpackChunkName: "q" */ "@/views/StartUp");

const features = store.getters.config;

const profileRoutes = [
  {
    path: "/profile",
    name: "profile",
    component: () => import(/* webpackChunkName: "p" */ "@/views/profile/Profile"),
    beforeEnter: ifAuthenticated,
  },
];

const batchRoutes = [
  {
    path: "/batches",
    name: "batches",
    component: () => import(/* webpackChunkName: "b" */ "@/views/batch/Batches"),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/batches/new",
    name: "newBatch",
    component: () => import(/* webpackChunkName: "b" */ "@/views/batch/BatchWizard"),
    beforeEnter: ifAuthenticated && features.useBatch,
  },
  {
    path: "/batches/input-files",
    name: "inputFiles",
    component: () => import(/* webpackChunkName: "b" */ "@/views/batch/InputFiles"),
    beforeEnter: ifAuthenticated && features.useBatch,
  },
];

const ongoingRoutes = [
  {
    path: "/ongoing/customers",
    name: "customers",
    component: () => import(/* webpackChunkName: "og" */ "@/views/ongoing/Customers"),
    beforeEnter: ifAuthenticated && features.useOngoing,
  },
  {
    path: "/ongoing/customers/new",
    name: "newCustomer",
    props: true,
    component: () => import(/* webpackChunkName: "og" */ "@/views/ongoing/Customers"),
    beforeEnter: ifAuthenticated && features.useOngoing,
  },
  {
    path: "/ongoing/customers/:id/view",
    name: "viewCustomer",
    props: true,
    component: () => import(/* webpackChunkName: "og" */ "@/views/ongoing/CustomerView"),
    beforeEnter: ifAuthenticated && features.useOngoing,
  },
  {
    path: "/ongoing/pipelines",
    name: "pipelines",
    component: () => import(/* webpackChunkName: "pp" */ "@/views/ongoing/Pipelines"),
    beforeEnter: ifAuthenticated && features.useOngoing,
  },
];

const dataSourcesRoutes = [
  {
    path: "/admin/data-sources",
    name: "dataSources",
    component: () =>
      store.getters.userRole === "SYS_ADMIN" &&
      import(/* webpackChunkName: "um" */ "@/views/admin/datasources/DataSources"),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/admin/summary-reports",
    name: "summaryReports",
    component: () =>
      store.getters.userRole === "SYS_ADMIN" &&
      import(/* webpackChunkName: "sr" */ "@/views/admin/reports/SummaryReports"),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/admin/custom-source",
    name: "customSource",
    component: () =>
      store.getters.userRole === "SYS_ADMIN" &&
      import(/* webpackChunkName: "cs" */ "@/views/admin/datasources/CustomSource"),
    beforeEnter: ifAuthenticated,
  },
];

const userManagementRoutes = [
  {
    path: "/admin/user-management",
    name: "userManagement",
    component: () =>
      (store.getters.userRole === "SYS_ADMIN" || store.getters.userRole === "ADMIN") &&
      import(/* webpackChunkName: "um" */ "@/views/admin/acl/UserManagement"),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/admin/tenant-management",
    name: "tenantManagement",
    component: () =>
      (store.getters.userRole === "SYS_ADMIN" || store.getters.userRole === "ADMIN") &&
      import(/* webpackChunkName: "um" */ "@/views/admin/acl/TenantManagement"),
    beforeEnter: ifAuthenticated,
  },
];

const supervisionRoutes = [
  {
    path: "/supervision/users",
    name: "supervisionCreateUsers",
    component: () =>
      import(/* webpackChunkName: "um" */ "@/views/supervision/users/SupervisionUsersDashboard"),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/supervision/dashboard/chiefsupervisor",
    name: "chiefSupervisorDashboard",
    component: () =>
      import(
        /* webpackChunkName: "um" */ "@/views/supervision/querylogs/ChiefQuerylogsDashboard"
      ),
    beforeEnter: ifAuthenticated,
  },  
  {
    path: "/supervision/dashboard/supervisor",
    name: "supervisionSupervisorDashboard",
    component: () =>
      import(
        /* webpackChunkName: "um" */ "@/views/supervision/querylogs/SupervisorQuerylogsDashboard"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/supervision/dashboard/analyst",
    name: "supervisionWorkerDashboard",
    component: () =>
      import(/* webpackChunkName: "um" */ "@/views/supervision/querylogs/WorkerQuerylogsDashboard"),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/supervision/report/:userRole",
    name: "supervisionReport",
    component: () =>
      import(
        /* webpackChunkName: "um" */ "@/components/supervision/reports/DailySupervisionReport"
      ),
    beforeEnter: ifAuthenticated,
    props: true,
  },
  {
    path: "/supervision/report/dashboard/chiefsupervisor",
    name: "chiefSupervisionSupervisorReportsDashboard",
    component: () =>
      import(/* webpackChunkName: "um" */ "@/views/supervision/reports/ChiefSupervisorReportsDashboard"),
    beforeEnter: ifAuthenticated,
    // props: true,
  },
  {
    path: "/supervision/report/dashboard/supervisor",
    name: "supervisionSupervisorReportsDashboard",
    component: () =>
      import(/* webpackChunkName: "um" */ "@/views/supervision/reports/SupervisorReportsDashboard"),
    beforeEnter: ifAuthenticated,
    // props: true,
  },
  {
    path: "/supervision/report/dashboard/analyst",
    name: "supervisionWorkerReportsDashboard",
    component: () =>
      import(/* webpackChunkName: "um" */ "@/views/supervision/reports/WorkerReportsDashboard"),
    beforeEnter: ifAuthenticated,
    // props: true,
  },
  {
    path: "/supervision/report/dashboard/table",
    name: "supervisionReportTable",
    component: () =>
      import(
        /* webpackChunkName: "um" */ "@/components/supervision/reports/table/SupervisionReportTable"
      ),
    beforeEnter: ifAuthenticated,
    props: true,
  },
];

const reportsRoutes = [
  {
    path: "/reports/daily",
    name: "dailyReports",
    component: () => import(/* webpackChunkName: "u" */ "@/views/reports/DailyReports"),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/reports/activity",
    name: "activityReports",
    component: () =>
      store.getters.userRole === "SYS_ADMIN" &&
      import(/* webpackChunkName: "u" */ "@/views/reports/ActivityReports"),
    beforeEnter: ifAuthenticated,
  },
];

const searchAndQueryRoutes = [
  {
    path: "/search",
    name: "search",
    component: fallBackComponent,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/query",
    name: "query",
    component: () => import(/* webpackChunkName: "q" */ "@/views/aml/Query"),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/query-log",
    name: "queryLog",
    component: () => import(/* webpackChunkName: "ql" */ "@/views/query-log/QueryLog"),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/query-log/:id",
    name: "queryLogDetails",
    component: () => import(/* webpackChunkName: "ql" */ "@/views/query-log/QueryLogDetail"),
    beforeEnter: ifAuthenticated,
    props: true,
  },

  {
    path: "/compare-names",
    name: "compareNames",
    component: () => import(/* webpackChunkName: "cn" */ "@/views/names-compare/NamesComparison"),
    beforeEnter: ifAuthenticated,
  },
];

const adverseRoutes = [
  {
    path: "/ad-media/search",
    name: "adSearch",
    component: () => import(/* webpackChunkName: "as" */ "@/views/admedia/AdQuery"),
    beforeEnter: ifAuthenticated && features.useAdverse,
  },
];

const addressSearchRoutes = [
  {
    path: "/address/search",
    name: "addressSearch",
    component: () => import(/* webpackChunkName: "ac" */ "@/views/address/AddressCheck"),
    beforeEnter: ifAuthenticated && features.useGeoSearch,
  },
  {
    path: "/crm/search",
    name: "crmSearch",
    component: () => import(/* webpackChunkName: "ac" */ "@/views/crm/CRMSearch"),
    beforeEnter: ifAuthenticated && features.useGeoSearch,
  },
];

const loginAndSignupRoutes = [
  {
    path: "/login",
    name: "login",
    component: Login,
    // beforeEnter: ifNotAuthenticated,
  },
];

const routes = [
  {
    path: "/index.html",
    // redirect: '/search',
    name: "index",
    component: fallBackComponent,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/",
    // redirect: '/search',
    name: "home",
    component: fallBackComponent,
    beforeEnter: ifAuthenticated,
  },
  ...profileRoutes,
  ...searchAndQueryRoutes,
  ...adverseRoutes,
  ...batchRoutes,
  ...dataSourcesRoutes,
  ...ongoingRoutes,
  ...reportsRoutes,
  ...userManagementRoutes,
  ...supervisionRoutes,
  ...loginAndSignupRoutes,
  ...addressSearchRoutes,
];

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
