<template>
  <v-list-group>
    <template v-slot:activator>
      <v-list-item-icon>
        <v-icon>mdi-database</v-icon>
      </v-list-item-icon>
      <v-list-item-title>Data sources</v-list-item-title>
    </template>
    <v-divider />

    <DrawerItem
      title="Sources in-use"
      route="/admin/data-sources"
      icon="keyboard_arrow_right"
    />
    <DrawerItem
      title="Available sources"
      route="/admin/summary-reports"
      icon="keyboard_arrow_right"
    />
    <DrawerItem
      title="Add Entity "
      route="/admin/custom-source"
      icon="keyboard_arrow_right"
    />
    <v-divider />
  </v-list-group>
</template>

<script>
import DrawerItem from '../DrawerItem';

export default {
  components: { DrawerItem },
  props: {
    features: Object,
  },
};
</script>
