import BaseAPIClient from '@/services/baseClient';

export class IndexAPIClient extends BaseAPIClient {
  createInstance() {
    super.createInstance();
    this.instance.defaults.baseURL = process.env.NODE_ENV === 'production'
      ? '/api/v1/index/'
      : process.env.VUE_APP_INDEX_API_URI;
  }

  async index(entity) {
    return this.instance.post('/entities', entity);
  }
}

export default new IndexAPIClient();
