<template>
    <transition name="fade">
        <LvProgressBar mode="indeterminate" color="#38b2ac" />
    </transition>
</template>

<script>
import LvProgressBar from "lightvue/progress-bar";

export default {
    data() {
        return {};
    },
    components: {
        LvProgressBar: LvProgressBar,
    },
};
</script>
