var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.isUserSystemAdmin() ||
    _vm.isUserAdmin() ||
    _vm.isUserSupervisor() ||
    _vm.isUserWorker() ||
    _vm.isUserChiefSupervisor()
  )?_c('v-list-group',{scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',[_c('v-icon',[_vm._v("person_add")])],1),_c('v-list-item-title',[_vm._v("User managment")])]},proxy:true}],null,false,4207248325)},[_c('v-divider'),(_vm.isUserSystemAdmin() || _vm.isUserAdmin())?_c('DrawerItem',{attrs:{"title":"User managment","route":"/admin/user-management","icon":"keyboard_arrow_right"}}):_vm._e(),(_vm.isUserSystemAdmin() || _vm.isUserAdmin())?_c('DrawerItem',{attrs:{"title":"Tenants","route":"/admin/tenant-management","icon":"keyboard_arrow_right"}}):_vm._e(),_c('v-divider')],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }