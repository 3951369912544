import BaseAPIClient from './baseClient';

export class ReportsClient extends BaseAPIClient {
  constructor() {
    super();

    this.instance.defaults.baseURL = process.env.NODE_ENV === 'production'
      ? '/api/v1/reports'
      : process.env.VUE_APP_REPORTS_API_URI;
  }

  async getUsernames(params) {
    return this.instance.get('/usernames', { params });
  }

  async getGroups(params) {
    return this.instance.get('/groups', { params });
  }

  async getDailyReports(params) {
    return this.instance.get('/daily', { params });
  }

  async getActivityReports(params) {
    return this.instance.get('/activity', { params });
  }
}

export default new ReportsClient();
