const Labels = {
    OPEN: "open",
    REOPENED: "reopened",
    PENDING: "pending",
    SUBMITTED: "submitted",
    CLOSED: "closed"
  };

const labelsList = Object.values(Labels)

const autoClosed = 'Auto closed'

export {Labels , labelsList , autoClosed}