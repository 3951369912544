import BaseAPIClient from './baseClient';

class ConfigClient extends BaseAPIClient {
  createInstance() {
    super.createInstance();

    this.baseURL = process.env.NODE_ENV === 'production'
    ? '/api/v1'
    : 'http://localhost:4567/api/v1';

    this.instance.defaults.baseURL = this.baseURL;
  }

  async getConfigFile() {
    const response = await this.instance.get('/config');

    return response.data;
  }
}

export default new ConfigClient();
