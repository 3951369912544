import { merge } from 'lodash';
import batchClient from '@/services/batchClient';
import crudHelper from '../helpers/crud';

const batchActions = {
  READ: batchClient.getBatch.bind(batchClient),
  READ_LIST: batchClient.getBatches.bind(batchClient),
  CREATE: batchClient.newBatch.bind(batchClient),
  UPDATE: batchClient.updateBatch.bind(batchClient),
  DELETE: batchClient.deleteBatch.bind(batchClient),
};

const batchStore = crudHelper('batch', '_id', batchActions);

const inputFilesActions = {
  READ: batchClient.getFile.bind(batchClient),
  READ_LIST: batchClient.getFiles.bind(batchClient),
  CREATE: batchClient.newFile.bind(batchClient),
  UPDATE: batchClient.updateFile.bind(batchClient),
  DELETE: batchClient.deleteFile.bind(batchClient),
};

const inputFileStore = crudHelper('inputFile', '_id', inputFilesActions);

const executeActions = {
  CREATE: batchClient.runBatch.bind(batchClient),
};

const executeStore = crudHelper('run', '_id', executeActions);

const module = merge({ namespaced: true }, batchStore, inputFileStore, executeStore);
export default module;
