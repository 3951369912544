<template>
  <v-list-group v-if="this.features.useOngoing">
    <template v-slot:activator>
      <v-list-item-icon>
        <v-icon>refresh</v-icon>
      </v-list-item-icon>
      <v-list-item-title>On-going</v-list-item-title>
    </template>

    <v-divider />

    <DrawerItem
      title="Tracked entities"
      route="/ongoing/customers"
      icon="keyboard_arrow_right"
    />

    <DrawerItem
      title="Pipeline Operations"
      route="/ongoing/pipelines"
      icon="keyboard_arrow_right"
    />
    <!--          <DrawerItem title="Add entity" route="/ongoing/customers/new" icon="keyboard_arrow_right"-->
    <!--                      />-->
    <!--          <DrawerItem title="Alerts" route="/ongoing/alerts" icon="keyboard_arrow_right"-->
    <!--                      />-->
    <v-divider />
  </v-list-group>
</template>

<script>
import DrawerItem from '../DrawerItem';

export default {
  components: { DrawerItem },
  props: {
    features: Object,
  },
};
</script>
